var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.actionType === 'edit')?_c('h1',{staticClass:"text-primary mb-5 font-45px mt-xl-2"},[_vm._v(" "+_vm._s(_vm.$t("documents_informations"))+" ")]):_vm._e(),_c('div',{staticClass:"giga-upload_files_list"},[_vm._l((_vm.documents),function(document,index){return [_c('div',{key:index},[(document.fileName)?_c('div',{key:document.fileName,staticClass:"giga-upload_file_item"},[_c('div',{staticClass:"giga-upload_file_action"},[_c('button',{staticClass:"upload_file_delete_action",on:{"click":function($event){$event.preventDefault();return _vm.deleteDraft(document)}}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.256 8L15.52 2.736C15.824 2.448 16 2.048 16 1.6C16 0.72 15.28 0 14.4 0C13.952 0 13.552 0.176 13.264 0.464L8 5.744L2.736 0.464C2.448 0.176 2.048 0 1.6 0C0.72 0 0 0.72 0 1.6C0 2.048 0.176 2.448 0.464 2.736L5.744 8L0.48 13.264C0.176 13.552 0 13.952 0 14.4C0 15.28 0.72 16 1.6 16C2.048 16 2.448 15.824 2.736 15.536L8 10.256L13.264 15.52C13.552 15.824 13.952 16 14.4 16C15.28 16 16 15.28 16 14.4C16 13.952 15.824 13.552 15.536 13.264L10.256 8Z","fill":"#6B829A"}})])])]),_c('div',{staticClass:"giga-upload_file_name"},[_c('i',{staticClass:"extention-icon"},[(_vm.getFileExtention(document.originalFileName) == 'xlsx')?_c('img',{attrs:{"src":require("@/assets/images/extensions/xls.svg")}}):(
                  _vm.getFileExtention(document.originalFileName) == 'txt'
                )?_c('img',{attrs:{"src":require("@/assets/images/extensions/txt.svg")}}):(
                  _vm.getFileExtention(document.originalFileName) == 'pdf'
                )?_c('img',{attrs:{"src":require("@/assets/images/extensions/pdf.svg")}}):(
                  _vm.getFileExtention(document.originalFileName) == 'docx'
                )?_c('img',{attrs:{"src":require("@/assets/images/extensions/doc.svg")}}):(
                  _vm.getFileExtention(document.originalFileName) == 'doc'
                )?_c('img',{attrs:{"src":require("@/assets/images/extensions/doc.svg")}}):(
                  _vm.getFileExtention(document.originalFileName) == 'zip'
                )?_c('img',{attrs:{"src":require("@/assets/images/extensions/zip.svg")}}):_c('img',{attrs:{"src":require("@/assets/images/extensions/text.svg")}})]),_vm._v(" "+_vm._s(document.originalFileName)+" ")]),_c('div',{staticClass:"giga-upload_file_progress progress c-progress"},[_c('div',{class:{
                'progress-bar': true,
                'bg-danger': !_vm.files[index].success,
                'progress-bar-animated': _vm.files[index].active
              },style:({ width: _vm.files[index].progress + '%' }),attrs:{"role":"progressbar"}})]),(_vm.isPreviewInProgress)?_c('lottie-player',{staticClass:"gwt-loader",attrs:{"src":"https://assets10.lottiefiles.com/private_files/lf30_yxonmdwh.json","background":"transparent","speed":"1.8","loop":"","autoplay":""}}):_vm._e()],1):_vm._e()])]})],2),_c('VuePerfectScrollbar',{staticClass:"documents-scroll-area"}),(_vm.actionType === 'edit')?_c('b-card',{staticClass:"border-raduis-xl border-color-muted box-shadow-sm mt-4",attrs:{"body-class":"p-3"}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',[_c('b-button',{staticClass:"py-2 px-5",attrs:{"disabled":_vm.isDisabled,"pill":"","variant":"primary"},on:{"click":_vm.editDocumentType}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }